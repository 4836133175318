import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Section, Typography, Container, Row, SectionTitle } from "components/ui"
import React from "react"

const PrivacyPage = () => (
  <Layout>
    <SEO refKey="gdpr-sheet" />
    <Section color="primary">
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h1"
              align="center"
              title={(<>General Data Protection Regulation</>)}
              subTitle={(<>We summarized all the GDPR elements that should matter to you and your customer in a simplified sheet.</>)}
            />
          </div>
        </Row>
      </Container>
    </Section>

    <Section className="">
      <Container>
        <Row>
          <div className=" mx-auto">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                GDPR Control and links
              </h3>
            </div>

            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Individual Responsible for GDPR Compliance
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">Laurent Lemaire, Data Controller</span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Purpose of Processing
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      We process data to continuously improve our products and services, log data about performance and overall backup activities.
                      Our systems also process data for security and monitoring purposes..
                      Notice regarding the collection and use of Personally Identifiable Information (PII) can be found here: <a className="text-primary" href="https://simplebackups.com/privacy/#GDPR">https://simplebackups.com/privacy/#GDPR</a></span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Lawful Basis for Collection & Processing
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">All PII collected and processed within SimpleBackups is in accordance with an agreement between SimpleBackups and the Data Controller</span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Data Subject Access Requests (DSAR)
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">Requests for data access, modification or deletion may be sent to <a className="text-primary" href="mailto:info@simplebackups.com">info@simplebackups.com</a></span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Data Privacy & Cookie Policies
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      <a className="text-primary" href="https://simplebackups.com/privacy/#GDPR">https://simplebackups.com/privacy/</a><br/>
                      <a className="text-primary" href="https://simplebackups.com/cookie-policy/">https://simplebackups.com/cookie-policy/</a>
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Data Retention
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">Customer Data, including PII, is securely deleted from SimpleBackups systems within ninety (90) days of service termination or upon customer request.<br/>
SimpleBackups-controlled PII is deleted in accordance with internal policy, when it no longer has business value, or upon Data Subject request</span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Data Protection & Information Security
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
SimpleBackups maintains a comprehensive information security management system to protect and preserve the confidentiality, integrity and availability of Customer Data.</span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-500">
                    Breach Notification
                  </dt>
                  <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">Any breach of PII will be promptly reported to Customers, Data Subjects and Data Authorities in accordance with our Incident Response Policy and all applicable regulatory requirements.</span>
                  </dd>
                </div>

              </dl>
            </div>

          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)
export default PrivacyPage
