import PrivacyPage from "./system/_gdprSheetPage";

export default PrivacyPage

export const frontmatter = {
  pageMetaData: {
    refKey: "gdpr-sheet",
    title: "GDPR Sheet - We respect your privacy",
    description: "Short sized version of our GDPR policies.",
  }
}